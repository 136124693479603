import { FormProps } from '../../../';
import { FieldErrors } from '../../../';
import isPromise from '../../utils/isPromise';
import validateField from './fieldValidation';

const defaultValidate =
  (fields: FormProps['fields'], customValidate: FormProps['validate']) => async (values: any) => {
    const fieldErrors: FieldErrors = {};

    Object.keys(fields || {}).forEach((fieldKey: string) => {
      const errorMessage = validateField(fields[fieldKey], values[fields[fieldKey].name]);
      if (errorMessage) {
        fieldErrors[fieldKey] = errorMessage;
      }
    });

    let customErrors;
    if (isPromise(customValidate?.(values))) {
      customErrors = await customValidate?.(values);
    } else {
      customErrors = customValidate?.(values);
    }

    return { ...customErrors, ...fieldErrors };
  };

export default defaultValidate;
