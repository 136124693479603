import React from 'react';
import { useFormikContext } from 'formik';

import { useFormContext } from '../../context/FormContext';
import { FormElementType } from '../../types';
import DefaultFormField from '../InputFields';

interface Props {
  layout: string;
  containerClassName?: string;
}

function FieldProvider(props: Props) {
  const { layout, containerClassName } = props;
  const formik = useFormikContext();
  const { fields, FormField, overrides } = useFormContext();
  const Component = FormField || DefaultFormField;

  return (
    <Component
      key={layout}
      className="w-full"
      containerClassName={containerClassName}
      field={fields[layout]}
      formik={formik}
      overrides={overrides}
      type={FormElementType.FormField}
    />
  );
}

export default React.memo(FieldProvider);
