import { customTypeRenderer } from '../../../components/renderers';
import { FormCustomField, FormElementProps } from '../../../types';

const CustomInputField = (props: FormElementProps<FormCustomField>) => {
  const { field } = props;

  return customTypeRenderer({ fieldName: field.name, renderer: field.renderer, field });
};

export default CustomInputField;
