import React from 'react';
import c from 'classnames';

import { Stack } from '@lyearn/ui';

import { ErrorPosition } from '../../types';
import LabelAndDescription from '../InputFields/LabelAndDescription';
import { renderErrorMessage, renderNudgeMessage } from '../InputFields/renderers';
import { FormFieldContainerProps } from './types';

const FormFieldContainer: React.FC<React.PropsWithChildren<FormFieldContainerProps>> = (props) => {
  const {
    children,
    label,
    description,
    errorMessage,
    errorPosition = ErrorPosition.Bottom,
    required,
    overrides,
    layoutProps,
    tooltipHelperText,
    tooltipHelperTextPlacement,
    className,
    nudgeMessage,
    isSubmitting = false,
  } = props;

  const ErrorMessage = renderErrorMessage({
    errorMessage,
    errorPosition,
    ...overrides?.Error,
  });

  const nudgeMessageNode = renderNudgeMessage({
    nudgeMessage,
    ...overrides?.Error,
  });

  const showError = (isSubmitting || !nudgeMessageNode) && ErrorMessage;

  return (
    <>
      <Stack className={c('field-container', className)} direction="col" {...layoutProps}>
        {label || description ? (
          <LabelAndDescription
            description={description}
            label={label}
            mergedOverrides={overrides}
            required={required}
            tooltipHelperText={tooltipHelperText}
            tooltipHelperTextPlacement={tooltipHelperTextPlacement}
          />
        ) : null}
        {errorPosition === ErrorPosition.Top && showError ? ErrorMessage : null}
        {children}
      </Stack>
      {errorPosition === ErrorPosition.Bottom && showError ? ErrorMessage : null}
      {!showError ? nudgeMessageNode : null}
    </>
  );
};

export default FormFieldContainer;
