import { FieldOverrides } from '../types';

const DEFAULT_FIELD_OVERRIDES: FieldOverrides = {
  Label: {
    color: 'text-text-primary',
    variant: 'body-short-02',
    className: '',
    as: 'div',
  },
  Description: {
    color: 'text-text-secondary',
    variant: 'body-short-01',
  },
};

const DEFAULT_FIELD_OVERRIDES_MOBILE: FieldOverrides = {
  Label: {
    color: 'text-text-primary',
    variant: 'heading-xs',
    className: '',
    as: 'div',
  },
  Description: {
    color: 'text-text-secondary',
    variant: 'body-short-01',
  },
};

export const getDefaultOverrides = (isMobile: boolean) =>
  isMobile ? DEFAULT_FIELD_OVERRIDES_MOBILE : DEFAULT_FIELD_OVERRIDES;

export default DEFAULT_FIELD_OVERRIDES;
