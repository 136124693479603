import useMedia from '../useMedia';
import { BREAKPOINT_QUERIES } from './const';
import { ScreenTypeEnum, UseScreenReturnType } from './types';

export { BREAKPOINT_QUERIES };

export default function useScreen(): UseScreenReturnType {
  const screen = useMedia(
    [BREAKPOINT_QUERIES.Desktop, BREAKPOINT_QUERIES.Tablet, BREAKPOINT_QUERIES.Mobile],
    [ScreenTypeEnum.Desktop, ScreenTypeEnum.Tablet, ScreenTypeEnum.Mobile],
    ScreenTypeEnum.Desktop,
  );

  const isDesktop = screen === ScreenTypeEnum.Desktop;
  const isTablet = screen === ScreenTypeEnum.Tablet;
  const isMobile = screen === ScreenTypeEnum.Mobile;

  return { screen, isDesktop, isTablet, isMobile };
}
