import React from 'react';

import { Layout, SectionLayout } from '../../types';
import FieldsLayout from '../FieldsLayout';
import SectionsLayout from '../SectionsLayout';

interface LayoutProps {
  layout: Layout | SectionLayout;
  classes?: {
    className?: string;
  };
}

const FormLayout = (props: LayoutProps) => {
  const { layout, classes } = props;

  if (typeof layout[0] === 'string' || Array.isArray(layout[0])) {
    return <FieldsLayout className={classes?.className} layout={layout as Layout} />;
  }

  if (typeof layout[0] === 'object') {
    return <SectionsLayout layout={layout as SectionLayout} />;
  }

  return null;
};

export default React.memo(FormLayout);
