import React from 'react';
import c from 'classnames';

import { Stack } from '@lyearn/ui';

import FieldProvider from '../../components/FieldProvider';
import { useFormContext } from '../../context/FormContext';
import { formColumn } from '../../styles';
import { Layout } from '../../types';

interface FieldsLayoutProps {
  layout: Layout;
  className?: string;
}

interface RenderFieldsLayout extends FieldsLayoutProps {
  LayoutComponent: React.FC<FieldsLayoutProps>;
}

const renderFieldsLayout = (params: RenderFieldsLayout) => {
  const { LayoutComponent, layout } = params;
  const layoutStyles = LayoutComponent === FormRow ? 'native:me-24' : 'native:mb-32';

  if (Array.isArray(layout)) {
    return (
      <>
        {layout.map((layoutItem, index, arr) => {
          const isLastStyles = index !== arr.length - 1 ? layoutStyles : undefined;
          if (typeof layoutItem === 'string') {
            return (
              <FieldProvider
                key={layoutItem + '-FieldProvider'}
                containerClassName={isLastStyles}
                layout={layoutItem}
              />
            );
          }
          return <LayoutComponent key={index} className={isLastStyles} layout={layoutItem} />;
        })}
      </>
    );
  }

  return <FieldProvider layout={layout} />;
};

const FormColumn = ({ layout, className }: FieldsLayoutProps) => {
  const { overrides } = useFormContext();

  return (
    <Stack
      className={c(formColumn, className)}
      spacing={overrides?.spacing?.column ?? 'space-s-24'}>
      {renderFieldsLayout({ layout, LayoutComponent: FormRow })}
    </Stack>
  );
};

const FormRow = ({ layout, className }: FieldsLayoutProps) => {
  const { overrides } = useFormContext();

  return (
    <Stack className={className} direction="col" spacing={overrides?.spacing?.row ?? 'space-y-32'}>
      {renderFieldsLayout({ layout, LayoutComponent: FormColumn })}
    </Stack>
  );
};

export default React.memo(FormRow);
