import React from 'react';
import c from 'classnames';

import { InfoOutline, SparkleOutline } from '@lyearn/icons';
import { Box, Stack, Tooltip, TooltipProps, Typography } from '@lyearn/ui';

import { nudgeIconFill, nudgeTextColor } from '../../styles';
import { ErrorPosition, TypographyOverrides } from '../../types';

interface RenderErrorMessageParams extends TypographyOverrides {
  errorMessage: any;
  errorPosition: ErrorPosition;
}

export const renderErrorMessage = ({
  errorMessage,
  errorPosition,
  ...overrides
}: RenderErrorMessageParams) => {
  if (!errorMessage) {
    return null;
  }

  return (
    <Typography
      className={c(
        'ms-8',
        { 'mt-8': errorPosition === ErrorPosition.Bottom },
        { 'mb-8': errorPosition === ErrorPosition.Top },
      )}
      color="text-text-error"
      variant="body-short-03"
      {...overrides}>
      {errorMessage}
    </Typography>
  );
};

interface RenderNudgeMessageParams extends TypographyOverrides {
  nudgeMessage: any;
}

export const renderNudgeMessage = ({ nudgeMessage, ...overrides }: RenderNudgeMessageParams) => {
  if (!nudgeMessage) {
    return null;
  }

  return (
    <Stack className="mt-8 ms-8 space-s-6">
      <SparkleOutline className={c('h-16 w-16', nudgeIconFill)} />
      <Typography
        className={nudgeTextColor}
        color="text-text-error"
        variant="body-short-03"
        {...overrides}>
        {nudgeMessage}
      </Typography>
    </Stack>
  );
};

interface RenderTooltipHelperTextParams {
  tooltipHelperText?: string | React.ReactNode;
  tooltipHelperTextPlacement?: TooltipProps['placement'];
}

export const renderTooltipHelperText = ({
  tooltipHelperText,
  tooltipHelperTextPlacement,
}: RenderTooltipHelperTextParams) => {
  if (!tooltipHelperText) {
    return null;
  }

  return (
    <Tooltip placement={tooltipHelperTextPlacement} title={tooltipHelperText}>
      <Box component="span">
        <InfoOutline className="mb-2 inline h-16 w-16 text-icon-placeholder ms-4 hover:text-text-secondary" />
      </Box>
    </Tooltip>
  );
};

interface RenderDescriptionParams extends TypographyOverrides {
  description?: React.ReactNode;
}

export const renderDescription = ({
  description,
  className,
  ...overrides
}: RenderDescriptionParams) => {
  if (!description) {
    return null;
  }

  return (
    <Typography {...overrides} className={c('mt-4', className)}>
      {description}
    </Typography>
  );
};

interface RenderOptionalLabel {
  required?: boolean;
  optionalLabel?: string;
}

export const renderOptionalLabel = (params: RenderOptionalLabel) => {
  const { required = true, optionalLabel = '(optional)' } = params;

  if (required) {
    return null;
  }

  return (
    <Typography as="span" color="text-text-placeholder" variant="body-short-02">
      {optionalLabel}
    </Typography>
  );
};
