import React from 'react';

import { Box, Typography } from '@lyearn/ui';

import { SectionOverrides, TypographyOverrides } from '../../types';
import { renderTooltipHelperText } from '../InputFields/renderers';

const renderName = (
  name: string | React.ReactNode,
  overrides?: TypographyOverrides,
  tooltipHelperText?: string | React.ReactNode,
) => (
  <Typography variant="heading-s" {...overrides}>
    {name}
    {renderTooltipHelperText({ tooltipHelperText })}
  </Typography>
);

const renderDescription = (description: string, overrides?: TypographyOverrides) => (
  <Typography color="text-text-secondary" variant="body-short-01" {...overrides}>
    {description}
  </Typography>
);

const SectionHeader = ({
  name,
  description,
  overrides,
  tooltipHelperText,
}: {
  name?: string | React.ReactNode;
  description?: string;
  overrides?: SectionOverrides;
  tooltipHelperText?: string | React.ReactNode;
}) => (
  <Box className="mb-24 space-y-8 ms-8" {...overrides?.Header}>
    {name ? renderName(name, overrides?.Name, tooltipHelperText) : null}
    {description ? renderDescription(description, overrides?.Description) : null}
  </Box>
);

export default React.memo(SectionHeader);
