import { css } from '@emotion/css';

export const formColumn = css`
  & > * {
    flex-basis: 0;
    flex-grow: 1;
  }
`;

export const nudgeIconFill = css`
  fill: #5a27ee;
`;

export const nudgeTextColor = css`
  background: linear-gradient(90deg, #5a27ee 0%, #be0efc 53.13%, #bb01bf 100%);
  background-clip: text;
  text-fill-color: transparent;
`;

export const nudgeBorderStyles = css`
  &:before,
  &:hover::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 1px;
    background: linear-gradient(90deg, #7e5be7 0%, #ba4fdf 67.71%, #d62bda 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  &,
  :hover,
  :active,
  :focus-within,
  :focus-within:hover {
    border-color: transparent;
  }
`;
