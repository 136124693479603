import { useCallback } from 'react';
import { Field as FormikField, FieldProps } from 'formik';

import { setIn } from '@lyearn/core/utils/immutable';
import { chain } from '@lyearn/core/utils/immutable';

import { FormCustomField, RendererParams } from '../types';

interface CustomTypeRendererParams {
  fieldName: string;
  fieldKey?: string;
  renderer: FormCustomField['renderer'];
  field?: FormCustomField;
}

type RendererType = {
  fieldKey?: string;
  status?: any;
  renderer: React.FunctionComponent<RendererParams>;
  value: Omit<RendererParams, 'onChange' | 'setErrorElement' | 'setNudgeState'>;
  field?: FormCustomField;
};

const Renderer = ({ renderer, value, status, fieldKey, field }: RendererType) => {
  const { setFieldValue, setStatus, ...rest } = value;
  const onChange = useCallback(
    (value: any) => {
      setFieldValue(fieldKey ?? rest.name, value, true);
    },
    [fieldKey, rest.name, setFieldValue],
  );

  const setErrorElement = useCallback(
    (element: HTMLElement | null) => {
      setStatus(setIn(status ?? {}, `errorElements.${rest.name}`, element));
    },
    [rest.name, setStatus, status],
  );

  const setNudgeState = useCallback(
    (fieldName: string, value: string) => {
      setStatus(
        chain(status ?? {})
          .setIn(`nudge.${fieldName}`, value)
          .setIn('scrollToNudge', true)
          .value(),
      );
    },
    [setStatus, status],
  );

  return renderer({
    ...rest,
    setStatus,
    onChange,
    setFieldValue,
    setErrorElement,
    setNudgeState,
    field,
  });
};

export const customTypeRenderer = ({ fieldName, renderer, field }: CustomTypeRendererParams) => {
  if (!renderer) {
    return null;
  }

  return (
    <FormikField name={fieldName}>
      {({ field: { value, name }, meta: { error, touched }, form }: FieldProps) => {
        const {
          errors,
          values,
          status,
          setFieldValue,
          handleChange,
          setFieldError,
          setFieldTouched,
          handleBlur,
          setStatus,
        } = form;

        return (
          <Renderer
            field={field}
            renderer={renderer}
            status={status}
            value={{
              formik: form,
              value,
              values,
              error,
              errors,
              touched,
              name,
              setStatus,
              handleBlur,
              handleChange,
              setFieldValue,
              setFieldError,
              setFieldTouched,
            }}
          />
        );
      }}
    </FormikField>
  );
};
