import React from 'react';
import cn from 'classnames';

import { Box, Divider } from '@lyearn/ui';

import FormSection from '../../components/FormSection';
import { useFormContext } from '../../context/FormContext';
import { SectionLayout } from '../../types';

interface Props {
  layout: SectionLayout;
  showDivider?: boolean;
  className?: string;
}

const SectionsLayout = (props: Props) => {
  const { overrides } = useFormContext();
  const { layout, showDivider = true, className } = props;

  return (
    <Box className={cn(className, overrides?.Section?.spacing ?? 'space-y-56')}>
      {layout.map((section, index, arr) => (
        <FormSection
          key={section.id || index}
          index={index}
          layoutLength={arr.length}
          section={section}
          showDivider={showDivider}
        />
      ))}
    </Box>
  );
};

export default React.memo(SectionsLayout);
