import isEmail from 'validator/lib/isEmail';

import { Trans } from '@lyearn/i18n';

import fieldValidate from '../../helper/fieldValidate';
import {
  Field,
  FieldType,
  FieldValidationFunction,
  FieldValidationTypes,
  FormTextField,
} from '../../types';
import Input from '../text/Input';
import { FieldInputType } from '../types';

const fieldValidation: FieldValidationFunction = {
  [FieldValidationTypes.Required]: ({ value, validation }) => {
    if (!value) {
      return validation?.message || <Trans i18nKey="Form:FieldRequiredMessage" />;
    } else if (!isEmail(value)) {
      return <Trans i18nKey="Form:ValidEmailMessage" />;
    }
  },
  [FieldValidationTypes.Custom]: () => undefined,
};

const EmailInputField: FieldInputType<FormTextField> = {
  Input,
  type: FieldType.Email,
  validate: (field: Field, value: any) => {
    return fieldValidate({ field, value, fieldValidation });
  },
};

export default EmailInputField;
