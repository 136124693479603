import Fields from '../../fields';
import { Field } from '../../types';

const validateField = (field: Field, value: any) => {
  /* Validation based on Field validation property */
  if (field.validation && field.validation.length) {
    const formField = Fields[field.type];
    if (formField && formField.validate) {
      return formField.validate(field, value);
    }
    return undefined;
  }

  return undefined;
};
export default validateField;
