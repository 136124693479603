import isBoolean from 'lodash/isBoolean';

import { Trans } from '@lyearn/i18n';

import fieldValidate from '../../helper/fieldValidate';
import {
  Field,
  FieldType,
  FieldValidationFunction,
  FieldValidationTypes,
  FormSwitchField,
} from '../../types';
import { FieldInputType } from '../types';
import Input from './Input';

const fieldValidation: FieldValidationFunction = {
  [FieldValidationTypes.Required]: ({ value, validation }) => {
    if (!isBoolean(value)) {
      return validation?.message || <Trans i18nKey="Form:FieldRequiredMessage" />;
    }
  },
  [FieldValidationTypes.Custom]: () => undefined,
};

const SwitchInputField: FieldInputType<FormSwitchField> = {
  Input,
  layoutProps: { direction: 'row', justifyContent: 'between', spacing: 'space-s-12' },
  type: FieldType.Switch,
  validate: (field: Field, value: any) => {
    return fieldValidate({ field, value, fieldValidation });
  },
};

export default SwitchInputField;
