import React, { Ref } from 'react';
import { FormikErrors, FormikHelpers, FormikProps, FormikValues } from 'formik';

import { Field, FieldOverrides } from './Field';
import { Layout, SectionLayout } from './Section';

export interface Fields {
  [key: string]: Field;
}

export enum VisibilityOperators {
  Equal = 'EQUAL',
  NotEqual = 'NOT_EQUAL',
  In = 'IN',
}

export interface FieldVisibility {
  value: any;
  fieldKey: string;
  operator: VisibilityOperators;
}

export interface AutoSaveProps {
  debounceMs?: number;
}

export type FormRefType<T> = Ref<FormikProps<T>>;

export interface FormProps<T = any> extends AutoSaveProps {
  formName: string;
  validate?: (
    values: T,
  ) => void | Promise<FormikErrors<T>> | Partial<Record<keyof T, JSX.Element | string>>;
  fields: Fields;
  layout?: Layout;
  sectionLayout?: SectionLayout;
  initialValues: T;
  onChange?: Function;
  onSubmit: (values: T, formikHelpers: FormikHelpers<T>) => void | Promise<any>;
  setSubmitRef?: any;
  enableReinitialize?: boolean;
  validateOnBlur?: boolean;
  validateOnChange?: boolean;
  validateOnMount?: boolean;
  autoSave?: boolean;
  focusErrorOnSubmit?: boolean;
  FormField?: React.FC<FormElementProps>;
  overrides?: FieldOverrides;
  formRef?: FormRefType<T>;
  validateNudgeState?: (values: T, nudgeStates: any) => any | Promise<any>;
  children?: React.ReactNode;
}

export enum FormElementType {
  FormField = 'FORM_FIELD',
  FormSection = 'FORM_SECTION',
}

export interface FormElementProps<F extends Field = Field> {
  formik: any;
  field: F;
  className?: string;
  containerClassName?: string;
  type: FormElementType;
  overrides?: FieldOverrides;
}

export type FieldError = string | undefined | React.ReactNode | { [key: string]: string };

export type ScrollBlockPosition = 'center' | 'end' | 'nearest' | 'start';
export type SetFieldValue = FormikHelpers<FormikValues>['setFieldValue'];

export * from './actions';
export * from './Field';
export * from './Section';
export * from './ValidationSchema';
export type { FormikContextType, FormikHelpers } from 'formik';
