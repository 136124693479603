import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';

import { Layout, SectionLayout } from '../types';

export default function getOrderedFieldNames(fieldLayout: SectionLayout | Layout): string[] {
  if (!fieldLayout) {
    return [];
  }

  return fieldLayout.flatMap((layout) => {
    if (isArray(layout)) {
      return getOrderedFieldNames(layout as Layout);
    }

    if (isObject(layout)) {
      if (layout.renderer?.fieldKey) {
        return [layout.renderer?.fieldKey];
      }
      if (layout.layout) {
        return getOrderedFieldNames(layout.layout);
      }
      if (layout.subSections) {
        return getOrderedFieldNames(layout.subSections);
      }
      return [];
    }

    return [layout];
  });
}
