import React from 'react';
import cn from 'classnames';

import { Stack, TooltipProps, Typography } from '@lyearn/ui';

import { FieldOverrides } from '../../types';
import { renderDescription, renderOptionalLabel, renderTooltipHelperText } from './renderers';

type LabelAndDescriptionProps = {
  label: React.ReactNode;
  required?: boolean;
  tooltipHelperText?: string;
  tooltipHelperTextPlacement?: TooltipProps['placement'];
  description?: React.ReactNode;
  mergedOverrides?: FieldOverrides;
  className?: string;
};

const LabelAndDescription = ({
  label,
  required,
  tooltipHelperText,
  description,
  mergedOverrides,
  tooltipHelperTextPlacement,
  className,
}: LabelAndDescriptionProps) => {
  return (
    <Stack
      className={cn('field-header-container mb-8 flex-1 ms-8 xs:mb-16', className)}
      direction="col">
      <Stack>
        <Typography className="w-full" {...mergedOverrides?.Label}>
          {label} {renderOptionalLabel({ required })}{' '}
          {renderTooltipHelperText({ tooltipHelperText, tooltipHelperTextPlacement })}
        </Typography>
      </Stack>
      {renderDescription({ description, ...mergedOverrides?.Description })}
    </Stack>
  );
};

export default React.memo(LabelAndDescription);
