import React, { useMemo } from 'react';
import c from 'classnames';
import { Field, FieldProps } from 'formik';

import { Checkbox } from '@lyearn/ui';

import { FormCheckboxField, FormElementProps } from '../../../types';
import { checkboxStyles } from './styles';

function CheckboxField(props: FormElementProps<FormCheckboxField>) {
  const { field } = props;
  const inputProps = useMemo(() => ({ name: field.name }), [field.name]);

  return (
    <Field name={inputProps.name}>
      {({ field: { value, onChange } }: FieldProps) => (
        <Checkbox
          checked={value}
          className={c('my-1 flex-shrink-0 me-12 xs:me-4', checkboxStyles)}
          disabled={field.disabled}
          inputProps={inputProps}
          onChange={onChange}
          {...field.props}
        />
      )}
    </Field>
  );
}

export default React.memo(CheckboxField);
