import { Field, FieldError, FieldValidationFunction, FieldValidationTypes } from '../types';

type FieldValidateHelperParams = {
  field: Field;
  value: any;
  fieldValidation: FieldValidationFunction;
};

const fieldValidate = ({ field, value, fieldValidation }: FieldValidateHelperParams) => {
  if (field.validation && field.validation.length > 0) {
    let error: FieldError;
    field.validation.forEach((validation) => {
      if (error) {
        return;
      }

      const fieldValidationEntry = fieldValidation[validation.type];

      if (fieldValidationEntry && validation.type !== FieldValidationTypes.Custom) {
        error = fieldValidationEntry({ field, value, validation });
      }

      if (validation.type === FieldValidationTypes.Custom) {
        error = validation.validate?.(field, value);
      }
    });

    return error;
  }

  return undefined;
};

export default fieldValidate;
