import React, { memo, useCallback, useEffect } from 'react';
import { Form as FormikForm, useFormikContext } from 'formik';
import get from 'lodash/get';

import { FormProps } from '../types';

interface Props {
  children: React.ReactNode;
  formName: FormProps['formName'];
  onChange: FormProps['onChange'];
  setSubmitRef: FormProps['setSubmitRef'];
  validateNudgeState?: FormProps['validateNudgeState'];
  className?: string;
}

/* 
  This gives parent component access to Formik's Submit handler (to call submit) and provides Values as they change 
*/

const Form: React.FC<Props> = ({
  formName,
  onChange,
  children,
  setSubmitRef,
  validateNudgeState,
  className,
}) => {
  const formik = useFormikContext();

  useEffect(() => {
    setSubmitRef && setSubmitRef(() => formik.handleSubmit);
  }, [formik.handleSubmit, setSubmitRef]);

  const validateNudge = useCallback(async () => {
    const nudgeStates = await validateNudgeState?.(
      formik.values,
      get(formik.status, 'nudge') ?? {},
    );

    formik.setStatus({ ...formik.status, nudge: nudgeStates });
  }, [formik, validateNudgeState]);

  useEffect(() => {
    onChange && onChange(formik);
    if (validateNudgeState) {
      validateNudge();
    }
    /**
     * We only want to run this when the values change, not when the formik object changes
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values, formik.isValid]);

  return (
    <FormikForm className={className} name={formName}>
      {children}
    </FormikForm>
  );
};

const MemoForm = memo(Form);

export default MemoForm;
