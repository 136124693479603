import { Trans } from '@lyearn/i18n';

import fieldValidate from '../../helper/fieldValidate';
import {
  Field,
  FieldType,
  FieldValidationFunction,
  FieldValidationTypes,
  FormTextField,
} from '../../types';
import Input from '../text/Input';
import { FieldInputType } from '../types';

const fieldValidation: FieldValidationFunction = {
  [FieldValidationTypes.Required]: ({ value, validation }) => {
    if (!isFinite(value)) {
      return validation?.message || <Trans i18nKey="Form:FieldRequiredMessage" />;
    }
  },
  [FieldValidationTypes.Custom]: () => undefined,
};

const NumberInputField: FieldInputType<FormTextField> = {
  Input,
  type: FieldType.Number,
  validate: (field: Field, value: any) => {
    return fieldValidate({ field, value, fieldValidation });
  },
};

export default NumberInputField;
