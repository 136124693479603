import get from 'lodash/get';
import intersection from 'lodash/intersection';

import { FieldVisibility, VisibilityOperators } from '../types';

const evaluateVisibility = (fieldValue: any, operator: VisibilityOperators, value: any) => {
  switch (operator) {
    case VisibilityOperators.In: {
      if (Array.isArray(fieldValue)) {
        return Boolean(intersection(fieldValue, value).length);
      }
      return value.includes(fieldValue);
    }
    case VisibilityOperators.Equal: {
      return fieldValue === value;
    }
    case VisibilityOperators.NotEqual: {
      return fieldValue !== value;
    }
    default:
      return false;
  }
};

export const getFieldVisibility = (formik: any, visibility: FieldVisibility[]) => {
  const values = formik.values;

  return visibility.every((fieldVisibility) => {
    const { fieldKey, operator, value } = fieldVisibility;
    const fieldValue = get(values, fieldKey);

    return evaluateVisibility(fieldValue, operator, value);
  });
};
