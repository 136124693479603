import keyBy from 'lodash/keyBy';

import Checkbox from './checkbox';
import Custom from './custom';
import Email from './email';
import Multiline from './multiline';
import MultiPicklist from './multiPicklist';
import Number from './number';
import Password from './password';
import Picklist from './picklist';
import Rating from './rating';
import Switch from './switch';
import Text from './text';

export const ALL_FIELDS = [
  Checkbox,
  Custom,
  Email,
  Multiline,
  MultiPicklist,
  Number,
  Password,
  Picklist,
  Switch,
  Text,
  Rating,
];

const Fields = keyBy(ALL_FIELDS, 'type');

export default Fields;
