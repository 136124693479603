import isBoolean from 'lodash/isBoolean';

import { Trans } from '@lyearn/i18n';

import fieldValidate from '../../helper/fieldValidate';
import {
  Field,
  FieldType,
  FieldValidationFunction,
  FieldValidationTypes,
  FormCheckboxField,
} from '../../types';
import { FieldInputType } from '../types';
import Input from './Input';

const fieldValidation: FieldValidationFunction = {
  [FieldValidationTypes.Required]: ({ value, validation }) => {
    if (!isBoolean(value)) {
      return validation?.message || <Trans i18nKey="Form:FieldRequiredMessage" />;
    }
  },
  [FieldValidationTypes.Custom]: () => undefined,
};

const CheckboxInputField: FieldInputType<FormCheckboxField> = {
  Input,
  layoutProps: {
    direction: 'row-reverse',
    alignItems: 'start',
    justifyContent: 'end',
    spacing: '',
    className: '',
  },
  type: FieldType.Checkbox,
  validate: (field: Field, value: any) => {
    return fieldValidate({ field, value, fieldValidation });
  },
};

export default CheckboxInputField;
