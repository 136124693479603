import { useEffect, useRef } from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';
import intersection from 'lodash/intersection';

import { setIn } from '@lyearn/core/utils/immutable';
import Platform from '@lyearn/core/utils/Platform';

import { useFormContext } from '../context/FormContext';
import { ScrollBlockPosition } from '../types';

const isNative = Platform.OS !== 'web';
const EMPTY_OBJECT = {};

const useErrorFocus = ({
  fieldName,
  scrollBlockPosition = 'center',
}: {
  fieldName?: string;
  scrollBlockPosition?: ScrollBlockPosition;
}) => {
  const fieldRef = useRef<HTMLDivElement>(null);
  const { focusErrorOnSubmit, orderedFieldNames } = useFormContext();
  const { errors, isSubmitting, isValidating, status, setStatus } = useFormikContext();
  const fieldErrorElement = get(status, `errorElements.${fieldName}`);
  const nudgeStates = get(status, 'nudge') || EMPTY_OBJECT;
  const scrollToNudge = !!get(status, 'scrollToNudge');

  useEffect(() => {
    if (isNative) {
      return;
    }

    // Validate and focus on error
    const shouldValidateError = Boolean(
      fieldName && focusErrorOnSubmit && isSubmitting && !isValidating && errors,
    );

    if (shouldValidateError) {
      const firstErrorField = intersection(orderedFieldNames, Object.keys(errors))[0];
      const shouldFocus = Boolean(fieldName === firstErrorField && (fieldErrorElement || fieldRef));
      if (shouldFocus) {
        if (fieldErrorElement) {
          fieldErrorElement.scrollIntoView({ behavior: 'smooth', block: scrollBlockPosition });
        } else {
          fieldRef.current?.scrollIntoView({ behavior: 'smooth', block: scrollBlockPosition });
        }
        return;
      }
    }

    // Validate and focus on nudge
    const shouldValidateNudge = Boolean(!isSubmitting && fieldName && scrollToNudge);
    if (shouldValidateNudge) {
      const firstNudgeField = intersection(orderedFieldNames, Object.keys(nudgeStates))[0];
      const shouldFocus = Boolean(fieldName === firstNudgeField && (fieldErrorElement || fieldRef));
      if (shouldFocus) {
        setStatus(setIn(status ?? {}, 'scrollToNudge', false)); // Disable scroll to nudge as should happen only once
        if (fieldErrorElement) {
          fieldErrorElement.scrollIntoView({ behavior: 'smooth', block: scrollBlockPosition });
        } else {
          fieldRef.current?.scrollIntoView({ behavior: 'smooth', block: scrollBlockPosition });
        }
      }
    }
  }, [
    errors,
    fieldErrorElement,
    fieldName,
    focusErrorOnSubmit,
    isSubmitting,
    isValidating,
    nudgeStates,
    orderedFieldNames,
    scrollBlockPosition,
    scrollToNudge,
    setStatus,
    status,
  ]);

  return { fieldRef };
};

export default useErrorFocus;
