import { FormikHandlers, FormikHelpers } from 'formik';

import {
  CheckboxProps,
  InputProps,
  SelectProps,
  SelectSize,
  TooltipProps,
  TypographyType,
} from '@lyearn/ui';

import { FieldVisibility, SetFieldValue } from '../types';
import { FieldValidateEntry, FieldValidationTypes } from './ValidationSchema';
import { FieldError, FieldValidation, ScrollBlockPosition } from './';

export enum FieldType {
  Text = 'TEXT',
  Email = 'EMAIL',
  Switch = 'SWITCH',
  Number = 'NUMBER',
  Custom = 'CUSTOM',
  Password = 'PASSWORD',
  Picklist = 'PICKLIST',
  Checkbox = 'CHECKBOX',
  Multiline = 'MULTILINE',
  MultiPicklist = 'MULTI_PICKLIST',
  Rating = 'RATING',
}

export enum RatingType {
  Star = 'STAR',
  Number = 'NUMBER',
}

export interface PicklistValue {
  label: string;
  value: string;
}

export enum ErrorPosition {
  Top = 'TOP',
  Bottom = 'BOTTOM',
  Custom = 'CUSTOM',
}

export interface RendererParams {
  formik: any;
  value: any;
  name: string;
  values: any;
  error: any;
  errors: any;
  touched: any;
  setStatus: (status?: any) => void;
  handleBlur: FormikHandlers['handleBlur'];
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  onChange: (value: any) => void;
  setFieldError: (field: string, message: string | undefined) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  setErrorElement: (element: HTMLElement | null) => void;
  setNudgeState: (fieldName: string, value: string) => void;
  field?: FormCustomField;
}

interface ClassNameProps {
  className?: string;
}

export type TypographyOverrides = Pick<TypographyType, 'color' | 'variant' | 'as' | 'className'>;

export interface SectionOverrides {
  Container?: ClassNameProps;
  Header?: ClassNameProps;
  Name?: TypographyOverrides;
  Description?: TypographyOverrides;
  spacing?: string;
}

export interface FieldOverrides {
  Label?: TypographyOverrides;
  Description?: TypographyOverrides;
  Error?: TypographyOverrides;
  FieldContainer?: ClassNameProps;
  Section?: SectionOverrides;
  spacing?: {
    row?: string;
    column?: string;
  };
}

interface CommonFormField {
  name: string;
  required?: boolean;
  disabled?: boolean;
  label?: React.ReactNode;
  description?: React.ReactNode;
  tooltipHelperText?: string;
  tooltipHelperTextPlacement?: TooltipProps['placement'];
  visibility?: FieldVisibility[];
  validation?: FieldValidation;
  errorPosition?: ErrorPosition;
  scrollBlockPosition?: ScrollBlockPosition;
}

export interface FormCheckboxField extends CommonFormField {
  type: FieldType.Checkbox;
  props?: Pick<CheckboxProps, 'defaultChecked'>;
}

export interface FormSwitchField extends CommonFormField {
  type: FieldType.Switch;
}

export interface FormCustomField extends CommonFormField {
  type: FieldType.Custom;
  placeholder?: InputProps['placeholder'];
  rendererProps?: any;
  renderer: React.FunctionComponent<RendererParams>;
}

export interface FormEmailField extends CommonFormField {
  type: FieldType.Email | FieldType.Number | FieldType.Password;
  placeholder?: InputProps['placeholder'];
  size?: InputProps['size'];
}

export interface FormTextField extends CommonFormField {
  type: FieldType.Text | FieldType.Multiline;
  placeholder?: InputProps['placeholder'];
  size?: InputProps['size'];
  props?: Pick<
    InputProps,
    | 'autoFocus'
    | 'characterLimit'
    | 'className'
    | 'rows'
    | 'variant'
    | 'maxRows'
    | 'endAdornment'
    | 'startAdornment'
    | 'inputRef'
  >;
}

// TODO: Need to fix this
type PicklistOption = any;

export interface FormPicklistField<T extends PicklistValue = PicklistValue>
  extends CommonFormField {
  type: FieldType.Picklist | FieldType.MultiPicklist;
  placeholder?: string;
  size?: SelectSize;
  picklistValues: T[];
  props?: Pick<
    SelectProps<PicklistOption, false, false>,
    | 'optionLabelRenderer'
    | 'renderValue'
    | 'className'
    | 'disableCloseOnSelect'
    | 'blurOnSelect'
    | 'classes'
    | 'tagLabelRenderer'
    | 'renderTags'
  >;
}

export interface FormRatingField extends CommonFormField {
  type: FieldType.Rating;
  props: { max?: number; maxLabel?: string; minLabel?: string; ratingType: RatingType };
}

export type Field =
  | FormCheckboxField
  | FormSwitchField
  | FormCustomField
  | FormEmailField
  | FormTextField
  | FormPicklistField
  | FormRatingField;

export type FieldValidationParams = {
  field: Field;
  value: any;
  validation: FieldValidateEntry;
};

export type FieldValidationFunction = Record<
  FieldValidationTypes,
  (params: FieldValidationParams) => FieldError
>;
