import React, { useContext } from 'react';

import { FormProps } from '../../types';

interface FormContextValue {
  fields: FormProps['fields'];
  focusErrorOnSubmit: boolean;
  FormField?: FormProps['FormField'];
  overrides?: FormProps['overrides'];
  orderedFieldNames: string[];
}

const FormContext = React.createContext<FormContextValue | null>(null);

export const useFormContext = () => {
  const value = useContext(FormContext);

  if (!value) {
    throw new Error('[FormContext] No Provider');
  }

  return value;
};

export default FormContext;
