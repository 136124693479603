import { Field } from './Field';
import { FieldError } from '.';

export type FieldValidation = FieldValidateEntry[];

export interface FieldValidateEntry {
  type: FieldValidationTypes;
  message?: string;
  fieldLength?: number;
  regExp?: RegExp;
  validate?: (field: Field, value: any) => FieldError;
}

export enum FieldValidationTypes {
  Required = 'REQUIRED',
  Custom = 'CUSTOM',
}

export type FieldErrors = {
  [key: string]: FieldError;
};
