import React, { useMemo } from 'react';
import { Field, FieldProps } from 'formik';

import { Switch } from '@lyearn/ui';

import { FormElementProps, FormSwitchField } from '../../../types';

function SwitchField(props: FormElementProps<FormSwitchField>) {
  const { field } = props;
  const inputProps = useMemo(() => ({ name: field.name }), [field.name]);

  return (
    <Field name={inputProps.name}>
      {({ field: { value, onChange } }: FieldProps) => (
        <Switch
          checked={value}
          className="my-1 flex-shrink-0"
          disabled={field.disabled}
          inputProps={inputProps}
          onChange={onChange}
        />
      )}
    </Field>
  );
}

export default SwitchField;
