import React from 'react';
import { useFormikContext } from 'formik';
import get from 'lodash/get';

import useScreen from '@lyearn/core/hooks/useScreen';
import { cn } from '@lyearn/style';
import { Box, mergeOverrides } from '@lyearn/ui';

import { getDefaultOverrides } from '../../consts';
import Fields from '../../fields';
import { getFieldVisibility } from '../../helper/getFieldVisibility';
import useErrorFocus from '../../hooks/useErrorFocus';
import { ErrorPosition, FormElementProps } from '../../types';
import FormFieldContainer from '../FormFieldContainer';

const InputField = (props: FormElementProps) => {
  const { containerClassName, ...rest } = props;
  const { field, formik, overrides } = rest;
  const { isMobile } = useScreen();
  const visibility = field.visibility ? getFieldVisibility(formik, field.visibility) : true;
  const { fieldRef } = useErrorFocus({
    fieldName: field.name,
    scrollBlockPosition: field.scrollBlockPosition,
  });
  const formikContext = useFormikContext();

  if (!visibility) {
    return null;
  }

  const {
    label,
    description,
    tooltipHelperText,
    required,
    errorPosition = ErrorPosition.Bottom,
    tooltipHelperTextPlacement,
  } = field;
  const showError = Boolean(get(formik.touched, field.name) && get(formik.errors, field.name));
  const mergedOverrides = mergeOverrides(getDefaultOverrides(isMobile), overrides);

  const nudgeMessage = get(formik.status, `nudge.${field.name}`);

  const { Input, layoutProps } = Fields[field.type];
  const isSubmitting = formikContext?.isSubmitting ?? false;

  return (
    <Box
      ref={fieldRef}
      {...mergedOverrides.FieldContainer}
      className={cn(containerClassName, mergedOverrides.FieldContainer?.className)}>
      <FormFieldContainer
        description={description}
        errorMessage={showError ? get(formik.errors, field.name) : null}
        errorPosition={errorPosition}
        isSubmitting={isSubmitting}
        label={label}
        layoutProps={layoutProps}
        nudgeMessage={nudgeMessage}
        overrides={mergedOverrides}
        required={required}
        tooltipHelperText={tooltipHelperText}
        tooltipHelperTextPlacement={tooltipHelperTextPlacement}>
        <Input {...(rest as any)} />
      </FormFieldContainer>
    </Box>
  );
};

export default React.memo(InputField);
