import React, { useState } from 'react';
import { FormikValues } from 'formik';

import { Button, Typography } from '@lyearn/ui';

import { FieldType, RatingType } from '../';
import Form from '../';
import { FieldValidationTypes, VisibilityOperators } from './types';

const FormPreview = ({ withSections, autoSave = false, FormField = null }: any) => {
  const [formikSubmit, setFormikSubmit] = useState<Function>(() => {});
  return (
    <div>
      <Form
        FormField={FormField}
        autoSave={autoSave}
        fields={{
          title: {
            name: 'title',
            placeholder: 'Give a name to this lecture',
            description: 'Title description',
            label: 'Title',
            type: FieldType.Text,
            tooltipHelperText: 'Optimal title length is approximately 55 characters',
            validation: [
              {
                type: FieldValidationTypes.Required,
              },
              /* {
                type: FieldValidationTypes.MaxLength,
                fieldLength: 10,
                message: 'Max length is 10!',
              }, */
            ],
          },
          rating: {
            name: 'rating',
            label: 'E-Score',
            description: 'How happy are you at Sprinklr?',
            type: FieldType.Rating,
            props: {
              ratingType: RatingType.Star,
            },
            validation: [
              { type: FieldValidationTypes.Required, message: 'Please give the rating!' },
            ],
          },
          password: {
            name: 'password',
            placeholder: 'Give a name to this lecture',
            label: 'Password',
            type: FieldType.Password,
            tooltipHelperText: 'Minimum 6 characters are required',
          },
          description: {
            name: 'description',
            required: false,
            placeholder: "In this lecture you'll teach",
            label: 'Description',
            type: FieldType.Multiline,
            props: {
              characterLimit: 250,
            },
            validation: [{ type: FieldValidationTypes.Required, message: 'This is required!' }],
          },
          duration: {
            name: 'duration',
            placeholder: 'in hours',
            label: 'Duration',
            type: FieldType.Number,
            tooltipHelperText: 'The duration!',
            validation: [
              { type: FieldValidationTypes.Required, message: 'You must provide some duration!' },
            ],
          },
          customField: {
            name: 'customField',
            label: 'Custom view',
            type: FieldType.Custom,
            renderer: ({ values }: any) => (
              <div>Rendering custom view in form field! {values.title}</div>
            ),
          },
          assignTo: {
            type: FieldType.Picklist,
            name: 'assignTo',
            placeholder: 'Select a Class',
            label: 'Assign to',
            picklistValues: [
              { value: '5ee476b9f38b8f0a51ec5e40', label: 'Test Classroom 1' },
              { value: '5ee476b9f38b8f0a51ec5e42', label: 'Test Classroom 3' },
              { value: '5ee476b9f38b8f0a51ec5e41', label: 'Test Classroom 2' },
              { value: '5ee490e236b05100111dfe78', label: 'Nilesh Class' },
              { value: '5ee4ae4662e5e80011de2dc0', label: 'New class test' },
            ],
            validation: [
              { type: FieldValidationTypes.Required, message: 'Please select at least one!' },
            ],
          },
          classroomInfo: {
            name: 'classroomInfo',
            label: 'My Visibility is controlled',
            placeholder: 'Info for Test Classroom',
            type: FieldType.Text,
            visibility: [
              {
                fieldKey: 'assignTo',
                operator: VisibilityOperators.In,
                value: [
                  '5ee476b9f38b8f0a51ec5e42',
                  '5ee476b9f38b8f0a51ec5e40',
                  '5ee476b9f38b8f0a51ec5e41',
                ],
              },
            ],
          },
          allowComments: {
            type: FieldType.Switch,
            name: 'allowComments',
            label: 'Comments',
            description: 'Allow participants to privately comment on the assessment',
          },
          share: {
            type: FieldType.Checkbox,
            name: 'share',
            label: 'Share anonymously',
            description: 'I want to share my answers with HR without disclosing my name',
          },
          visibility: {
            name: 'visibility',
            type: FieldType.Custom,
            visibility: [
              {
                fieldKey: 'description',
                operator: VisibilityOperators.In,
                value: ['description'],
              },
            ],
            renderer: () => (
              <Typography>
                Should be visible when "description" is writter in Description field
              </Typography>
            ),
          },
        }}
        formName="My test form"
        initialValues={{
          title: 'Pre-filled values',
          description: '',
          duration: '',
          date: '',
          date1: '',
          dateTimeRange: {},
          assignTo: '',
          boolean: false,
          pricing: [],
          autocomplete: [],
          rating: null,
        }}
        layout={
          withSections
            ? undefined
            : [
                'title',
                'password',
                'description',
                // ['date', 'duration'],
                'duration',
                'assignTo',
                'classroomInfo',
                'allowComments',
                'share',
                // 'pricing',
                'customField',
                // 'autocomplete',
                'visibility',
                'rating',
                // 'date1',
                // 'dateTimeRange',
              ]
        }
        sectionLayout={
          withSections
            ? [
                {
                  id: 'Section 1',
                  name: 'Section1',
                  layout: ['title', 'description'],
                  description: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
                },
                {
                  id: 'Section 2',
                  name: 'Section2',
                  layout: [['duration', 'date']],
                },
                {
                  id: 'section-3',
                  name: 'Section3',
                  layout: ['customField', 'assignTo', 'allowComments'],
                },
                {
                  id: 'Section 4',
                  name: 'Section4',
                  layout: ['pricing'],
                  className: 'custom-className',
                },
                /* {
                  id: 'Section 5',
                  name: 'Section5',
                  type: GroupType.Section,
                  layout: ['autocomplete'],
                }, */
                {
                  id: 'custom-section',
                  name: 'Custom Section',
                  description: 'Custom Section Description',
                  renderer: {
                    fieldKey: 'custom-section',
                    component: () => {
                      return (
                        <div className="mb-30">
                          <Button loading={true}>My Custom section</Button>
                        </div>
                      );
                    },
                  },
                },
              ]
            : undefined
        }
        setSubmitRef={setFormikSubmit}
        validate={
          (async (values: FormikValues) => {
            /* Async validate test/example */
            const errors: any = {};

            return new Promise((resolve) => {
              if (values.description !== '1234') {
                errors.description = 'Required 1234';
              }

              resolve(errors);
            });
            // return errors;

            /* if (!values.description || values.description.length < 2) {
            errors.description = 'Description required';
          }
          if (!values.date) {
            errors.date = 'Date required';
          }
          if (!values.duration) {
            errors.duration = 'Duration required';
          }
          if (!values.assignTo) {
            errors.assignTo = 'Select a class to assign this lecture to';
          }
          return errors; */
          }) as any
        }
        onChange={(formik: any) => {
          console.log(formik);
        }}
        onSubmit={(values) => {
          // Get values here after submit without errors
          console.log('Submitting values', values);
        }}
      />
      <Button
        onClick={() => {
          formikSubmit();
        }}>
        Submit
      </Button>
    </div>
  );
};

export default FormPreview;
