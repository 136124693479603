import { memo, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import debounce from 'lodash/debounce';

import { AutoSaveProps } from '../../types';

const AutoSave = ({ debounceMs = 500 }: AutoSaveProps) => {
  const formik = useFormikContext();
  const debouncedSubmit = useMemo(
    () => debounce(formik.submitForm, debounceMs),
    [debounceMs, formik.submitForm],
  );

  useEffect(() => {
    debouncedSubmit();
  }, [debouncedSubmit, formik.values]);

  return null;
};

const MemoAutoSave = memo(AutoSave);

export default MemoAutoSave;
