import { FieldType, FormCustomField } from '../../types';
import { FieldInputType } from '../types';
import Input from './Input';

const CustomInputField: FieldInputType<FormCustomField> = {
  Input,
  type: FieldType.Custom,
};

export default CustomInputField;
