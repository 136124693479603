import { RendererParams } from './Field';
import { FieldVisibility, ScrollBlockPosition } from './';

export enum SectionType {
  Section = 'SECTION',
  SubSection = 'SUB_SECTION',
}

export interface SectionBasedLayout {
  id: string;
  name?: string | React.ReactNode;
  tooltipHelperText?: string | React.ReactNode;
  layout?: Layout;
  className?: string;
  description?: string;
  renderer?: {
    fieldKey: string;
    component: React.FunctionComponent<RendererParams>;
    scrollBlockPosition?: ScrollBlockPosition;
  };
  visibility?: FieldVisibility[];
  subSections?: SectionLayout;
  classes?: {
    layout?: {
      className?: string;
    };
  };
  showDivider?: boolean;
}

export type Layout = Array<string | Array<string> | Array<string | Array<string>>>;

export type SectionLayout = SectionBasedLayout[];
