export enum FormActionTypes {
  AddOptionSelectInput = 'ADD_OPTION_SELECT_INPUT',
}

interface AddOptionSelectInputAction {
  type: FormActionTypes.AddOptionSelectInput;
  payload: {
    name: string;
    label: string;
  };
}

export type FormActions = AddOptionSelectInputAction;

export type onFormAction = (action: FormActions) => void;
