import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Namespace, TFunction, UseTranslationOptions, UseTranslationResponse } from '../';

type CustomUseTranslationHook = typeof useTranslation;

export const loadedNamespaces = new Set<string>();
const nsLoadPromises = new Map<string, Promise<void> | boolean>();

const useTranslationOverride: CustomUseTranslationHook = (
  ___ns,
  props,
): UseTranslationResponse<Namespace, string> => {
  const translation = useTranslation(___ns, props);
  const { i18n, t, ready } = translation;

  const tFunctionOverride = useCallback(
    (__ns: Namespace, opts: UseTranslationOptions<string>) => {
      const namespace = Array.isArray(__ns) ? __ns : [__ns];

      namespace.forEach((_ns: string) => {
        const ns = _ns?.split?.(':')?.[0] || '';
        const nsToBeChecked = i18n.resolvedLanguage + '-' + ns;

        if (!ns || !_ns.includes(':') || loadedNamespaces.has(nsToBeChecked)) {
          return;
        }

        const isNamespacLoaded = i18n.hasLoadedNamespace(ns);

        if (isNamespacLoaded) {
          loadedNamespaces.add(nsToBeChecked);
          return;
        }

        if (ns && !loadedNamespaces.has(nsToBeChecked)) {
          const promiseEntry = nsLoadPromises.get(nsToBeChecked);

          if (promiseEntry && promiseEntry !== true) {
            throw promiseEntry;
          } else {
            nsLoadPromises.set(
              nsToBeChecked,
              i18n.loadNamespaces(ns, () => {
                loadedNamespaces.add(nsToBeChecked);
                nsLoadPromises.set(nsToBeChecked, true);
              }),
            );
          }
        }
      });

      return t(__ns, opts);
    },
    [t, i18n],
  );

  const ret: any = [tFunctionOverride as TFunction<Namespace, string, Namespace>, i18n, ready];

  ret.t = tFunctionOverride as TFunction<Namespace, string, Namespace>;
  ret.i18n = i18n;
  ret.ready = ready;

  return ret as UseTranslationResponse<Namespace, string>;
};

export { useTranslationOverride };
export default useTranslationOverride;
